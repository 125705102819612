import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { User, UserDto } from "../domain/user";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import axios from "axios";
import { ExactAPI } from "./api";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

export interface IAuthContextProps {
  isLoading: boolean;
  user: User | null;
  isAuthenticated: boolean;
  exactIsAuthenticated: boolean;
  exactUrl: string;
  token: string | undefined;
  login: (accessToken: string) => Promise<void>;
  logout: () => void;
  exactLogout: () => void;
}

export const AuthContext = createContext<IAuthContextProps>(
  {} as IAuthContextProps
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [exactIsAuthenticated, setExactIsAuthenticated] = useState(false);
  const [exactUrl, setExactUrl] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(['auth'])
  const [token, setToken] = useState(cookies?.auth?.access_token as string | undefined);

  const isAuthenticated = useMemo(() => {
    return !!user && !!token && !!exactIsAuthenticated && !isLoading;
  }, [user, token, isLoading, exactIsAuthenticated]);

  useEffect(() => {
    if (!token) {
      navigate("/");
      return
    }
    if (isAuthenticated && !location.pathname.includes('divisions')) {
      navigate("/divisions");
    } else if (location.pathname.includes('exact/auth')) {
      // do nothing, authenticating @ ExactAuth.tsx
      return;
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const authData = cookies.auth;
    if (!authData) {
      return;
    };
    const token = cookies.auth.access_token as string;
    if (token) {
      login(token);
    };
  }, []);

  async function login(accessToken: string) {
    if (isLoading) {
      return;
    }
    if (!accessToken) {
      toast.error("Authenticatiefout... cookies blocked?");
      navigate("/");
      return;
    }
    setIsLoading(true);
    
    axios
      .get<UserDto>("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((userInfo) => {
        toast("Welkom "+(userInfo.data.name || userInfo.data.email));
        setToken(accessToken);
        setUser(User.make(userInfo.data));
        ExactAPI.getAuthStatus(accessToken).then((res) => {
          setExactIsAuthenticated(res.auth);
          if (!res.auth) {
            setExactUrl(res.url);
          }
          setIsLoading(false);
        });
      })
      .catch((err) => {
        console.error(err);
        removeCookie("auth",  { path: '/' });
        setUser(null);
        setIsLoading(false);
      });
  }

  function logout() {
    googleLogout();
    removeCookie("auth",  { path: '/' });
    setUser(null);
    navigate("/");
  }

  function exactLogout() {
    token && ExactAPI.logout(token);
    setExactIsAuthenticated(false);
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated: isAuthenticated,
        exactIsAuthenticated,
        exactUrl,
        user,
        token,
        login,
        logout,
        exactLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
