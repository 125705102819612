import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  label: string;
  onClick?: () => void;
  icon?: IconDefinition;
  type?: "primary" | "danger" | "ghost" | "ghost-danger";
  disabled?: boolean;
}

export const Button: React.FC<Props> = ({ label, icon, onClick, type = "primary", disabled = false }) => {
  const colors = (type: string) => {
    switch (type) {
      case "primary":
        return "bg-primary border-primary text-white hover:border-secondary py-1 px-4 gap-4";
      case "danger":
        return "bg-bad border-bad text-white hover:border-secondary py-1 px-4 gap-4";
      case "ghost":
        return "text-primary border-transparent hover:text-secondary gap-2";
      case "ghost-danger":
        return "text-bad border-transparent hover:text-secondary gap-2";
    }
  }

  const disabledStyle = () => {
    return disabled ? "opacity-50 pointer-events-none" : "";
  }

  return (
    <button disabled={disabled} onClick={onClick} className={`${colors(type)} ${disabledStyle()} whitespace-nowrap self-center border-2 rounded-lg flex items-center h-10`}>
      {icon && <FontAwesomeIcon icon={icon} />}
      <p>{ label }</p>
    </button>
  )
}
