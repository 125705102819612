import { Button } from "../ui/Button";
import { useAuth } from "../../utils/useAuth";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { Loader } from "../ui/loader/Loader";
import React, { useState, useEffect } from 'react';
import { ExactAPI } from "../../utils/api";
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie'


export const Login: React.FC = () => {
  const { login, exactIsAuthenticated, exactUrl, isLoading, user, logout } = useAuth();
  const [isChecking, setIsChecking] = useState(true);
  const [showCredentialInputs, setShowCredentialInputs] = useState(false);
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [finalExactUrl, setFinalExactUrl] = useState(exactUrl);
  const [isSettingCredentials, setIsSettingCredentials] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['auth'])

  const scopes = process.env.REACT_APP_SCRIPT_SCOPES!;

  const handleLogin = useGoogleLogin({
    scope: scopes,
    onSuccess: (authData) => {  
      const hasAccess = checkScopes(authData);
      if (hasAccess) {
        setCookie('auth',authData, { path: '/' });
        login(authData.access_token);
      } else {

      }
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const checkScopes = (tokenResponse: TokenResponse) => {
      
    const grantedScope = tokenResponse.scope.split(" ");

    const hasAccess = scopes.split(" ").every(scope => {
      return grantedScope.includes(scope);
    })

    if (!hasAccess) {
      removeCookie('auth',{ path: '/' });
      toast.error("Missing scopes, please re-authorise");
      return logout()
    }
    return hasAccess;
  }

  useEffect(() => {
    const checkCredentials = async () => {
      const authData = cookies.auth;
      
      
      if (!authData) {
        setIsChecking(false);
        return;
      }
      const hasAccess = checkScopes(authData);
      if (!hasAccess) {
        setIsChecking(false);
        return;
      }

      try {
        const hasCredentials = await ExactAPI.checkCredentails(authData.access_token);
        setShowCredentialInputs(!hasCredentials);
        setIsChecking(false);
      } catch (error) {
        console.error('Failed to check credentials:', error);
        setIsChecking(false);
        toast.error("Failed to verify credentials. Please try again.");
      }
    };

    checkCredentials();
  }, []);

  const handleSetCredentials = async () => {
    setIsSettingCredentials(true);
    const authData = cookies.auth;
    if (!authData) {
      toast.error("Missing authorisaion, please re-authorise");
      setIsSettingCredentials(false);
      return;
    }
    try {
      const result = await ExactAPI.setCredentials(authData.access_token, { clientId, clientSecret });
      if (result) {
        setFinalExactUrl(result.url);
        setShowCredentialInputs(false); // Hide the credential inputs
      } else {
        console.error(JSON.stringify(result))
        toast.error("Failed to set credentials. Please try again.");
        throw new Error('Setting credentials failed');
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to set credentials. Please try again.");
    } finally {
      setIsSettingCredentials(false);
    }
  };

  if (isLoading || isChecking || isSettingCredentials) {
    return (
      <div className="flex flex-col items-center mt-16">
        <p className="mb-8">Een moment...</p>
        <Loader />
      </div>
    );
  }

  if (showCredentialInputs) {
    return (
      <div className="flex flex-col items-center mt-16 gap-3">
        <input
          type="text"
          placeholder="Exact Client ID"
          className="w-96 bg-bg rounded-lg p-3 border-2 border-gray cursor-pointer hover:border-primary hover:bg-white"
          value={clientId}
          onChange={e => setClientId(e.target.value)}
        />
        <input
          type="password"
          placeholder="Exact Client Secret"
          className="w-96 bg-bg rounded-lg p-3 border-2 border-gray cursor-pointer hover:border-primary hover:bg-white"
          value={clientSecret}
          onChange={e => setClientSecret(e.target.value)}
        />
        <Button label="Set Credentials" onClick={handleSetCredentials} />
      </div>
    );
  }

  if (user && !exactIsAuthenticated && !isLoading) {
    return (
      <div className="flex flex-col items-center mt-16">
        <Button label="Authenticeren met Exact" onClick={() => window.open(finalExactUrl || exactUrl, "_self")} />
        <Button label="Change Credentials" onClick={() => setShowCredentialInputs(true)} type="ghost" />
      </div>
    );
  }
  

  return (
    <div className="flex flex-col items-center mt-16">
      <Button label="Inloggen met Google" onClick={handleLogin} />
    </div>
  );
};
