import {
  faPause,
  faPen,
  faPlay,
  faRotateLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "../ui/Button";
import { useStateContext } from "../../state/StateContext";
import { syncEntitiesAPI } from "../../utils/api";
import { useAuth } from "../../utils/useAuth";

interface Props {
  setEditMode: (active: boolean) => void;
}

export const SyncActive: React.FC<Props> = ({ setEditMode }) => {
  const state = useStateContext();
  const { token } = useAuth();

  return (
    <>
      <div className="flex">
        <h3 className="text-xl text-secondary grow">Actieve endpoints</h3>
        <Button
          label="Aanpassen"
          onClick={() => {
            setEditMode(true);
          }}
          icon={faPen}
        />
      </div>
      {state.updateDivision!.syncEntities?.map((entity) => (
        <div key={entity.url} className="flex">
          <div className="flex flex-col gap-1 grow">
            <p className="text-md">{entity.url}</p>
            <p className="text-gray">
              Status: {entity.store?.status || "Onbekend"}
            </p>
          </div>
          <div className="flex gap-4">
            {entity.store?.status === "pause" && (
              <Button
                label="Start"
                onClick={() => {
                  syncEntitiesAPI.startEntitySync(token!, entity.key);
                }}
                icon={faPlay}
                type="ghost"
              />
            )}
            {entity.store?.status !== "pause" && (
              <Button
                label="Pauze"
                disabled={entity.store?.status !== "ok"}
                onClick={() => {
                  syncEntitiesAPI.pauseEntitySync(token!, entity.key);
                }}
                icon={faPause}
                type="ghost"
              />
            )}
            <Button
              label="Reset"
              disabled={entity.store?.status !== "ok"}
              onClick={() => {
                syncEntitiesAPI.resetEntitySync(token!, entity.key);
              }}
              icon={faRotateLeft}
              type="ghost"
            />
            <Button
              label="Verwijder"
              onClick={() => {
                syncEntitiesAPI.deleteEntitySync(token!, entity.key);
              }}
              icon={faTrash}
              type="ghost-danger"
            />
          </div>
        </div>
      ))}
    </>
  );
};
