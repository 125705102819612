import axios from "axios";
import { Division, DivisionDto } from "../domain/division";
import { BQLocationsWithArea, BQProject } from "../domain/bigQuery";
import { BaseEntity, Entity } from "../domain/entity";

const defaultData = {
  devMode: process.env.REACT_APP_DEVMODE,
  sessionState: "string"
};
const api = axios.create({
  method: "POST",
  baseURL: process.env.REACT_APP_SCRIPT_URL!,
});

export const divisionAPI = {
  test: async function (token: string, division: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "testSyncForDivision",
        parameters: [division]
      }
    });

    // hoist projectId to division itself bc easier later on
    return res.data.response.result as {trigger: string, entities: Entity[]};
  },

  getAll: async function (token: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "getDivisions",
        parameters: [true] // true to refresh divisions from Exact
      }
    });

    // hoist projectId to division itself bc easier later on
    return (res.data.response.result.divisions as Array<DivisionDto>).sort().map(division => ({
      ...division,
      projectId: division.syncEntities && division.syncEntities.length > 0 
        ? division.syncEntities[0].projectId
        : 0,
        projectLocation: '0' //todo cast location here
    })) as Array<Division>
  }
};

export const syncEntitiesAPI = {
  getKnown: async function(token: string, divisionId: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "knownEntities",
        parameters: [divisionId],
      }
    });
    return res.data.response.result as Array<BaseEntity>;
  },
  createEntitySync: async function (token: string, entity: Entity) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "createNewEntityAndTable",
        parameters: [entity],
      }
    });
    return res.data.response.result.status as string;
  },
  deleteEntitySync: async function (token: string, entityKey: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "removeEntity",
        parameters: [entityKey],
      }
    });
    return res.data.response.result.status as string;
  },
  pauseEntitySync: async function(token: string, entityKey: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "pauzeSync",
        parameters: [entityKey],
      }
    });
    return res.data.response.result.status as string;
  },
  resetEntitySync: async function(token: string, entityKey: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "reSync",
        parameters: [entityKey],
      }
    });
    return res.data.response.result.status as string;
  },
  getTriggerStatus: async function (token: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "getTriggerStatus"        
      }
    });

    // hoist projectId to division itself bc easier later on
    return res.data.response.result as string;
  },
  

  startEntitySync: async function(token: string, entityKey: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "startSync",
        parameters: [entityKey],
      }
    });
    return res.data.response.result.status as string;
  }
};

export const ExactAPI = {
  getAuthStatus: async function (token: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "getAuthStatus",
      }
    });
    return res.data.response.result;
  },
  logout: async function (token: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "logout",
      }
    });
    return res.data.response.result;
  },
  checkCredentails: async function (token: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "loadCredentialsForUser"
      }
    });
    return res.data.response.result;
  },
  setCredentials: async function (token: string, credentials: any) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "setCredentialsForUser",
        parameters: [credentials]
      }
    });
    return res.data.response.result;
  }
};

export type ProjectsDTO = {
  projects: Array<BQProject>,
  locations: BQLocationsWithArea
}
export const BQAPI = {
  getProjects: async function (token: string) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...defaultData,
        function: "listAllProjects",
      }
    });

    return (res.data.response.result as ProjectsDTO);
  }
};

export const AUTHAPI = {
  redirectUri: async function (token: string, params: any) {
    const res = await api.request({
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        parameters: [{'parameter':params}],
        function: "authCallback",
      }
    });
    if (res.data.error) {
      console.error(res.data.error);
      return false;
    } else {
      return true;
    }
  }
};