import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Button } from "../ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Division } from "../../domain/division";
import { BQProject } from "../../domain/bigQuery";
import { BQAPI, ProjectsDTO } from "../../utils/api";
import { useAuth } from "../../utils/useAuth";
import { useStateContext, useStateDispatch } from "../../state/StateContext";
import { ActionType } from "../../state/stateReducer";

interface Props {
  setEditMode: (active: boolean) => void;
}

export const NoSync: React.FC<Props> = ({ setEditMode }) => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [bqProjects, setBqProjects] = useState<ProjectsDTO>();
  const state = useStateContext();
  const dispatch = useStateDispatch()!;

  useEffect(() => {
    if (!token) {
      return;
    }

    BQAPI.getProjects(token).then((res) => {
      setBqProjects(res);
    });
  }, []);

  const changeSelectedProject = (projectId: string) => {
    dispatch({
      type: ActionType.updateDivision,
      value: {
        ...state.updateDivision!,
        projectId,
      },
    });
  };

  const changeSelectedLocation = (projectLocation: string) => {
    dispatch({
      type: ActionType.updateDivision,
      value: {
        ...state.updateDivision!,
        projectLocation,
      },
    });
  };

  return (
    <>
      <div className="flex gap-4 items-center">
        <FontAwesomeIcon
          className="text-2xl cursor-pointer hover:text-primary md:hidden"
          icon={faArrowLeft}
          onClick={() => navigate("..")}
        />
        <div>
          <h2 className="text-2xl text-secondary">
            {state.updateDivision!.name}
          </h2>
          <p className="text-gray text-sm">Geen sync ingesteld</p>
        </div>
      </div>
      <div className="flex gap-3 flex-wrap">
        <select
          disabled={!bqProjects?.projects?.length}
          value={state.updateDivision?.projectId}
          onChange={(e) => changeSelectedProject(e.target.value)}
          className="cursor-pointer border-2 border-gray rounded-lg grow h-10 disabled:cursor-progress"
        >
          <option disabled value={0}>
            BigQuery project selecteren...
          </option>
          {bqProjects?.projects?.map((project) => (
            <option key={project.id} value={project.id}>
              {project.id}
            </option>
          ))}
        </select>

        <select
          disabled={!bqProjects?.locations}
          value={state.updateDivision?.projectLocation}
          onChange={(e) => changeSelectedLocation(e.target.value)}
          className="cursor-pointer border-2 border-gray rounded-lg grow h-10 disabled:cursor-progress"
        >
          <option disabled value="0">
            BigQuery locatie selecteren...
          </option>
          {Object.keys(bqProjects?.locations || {}).map((areaKey) => (
            <optgroup key={areaKey} label={areaKey}>
              {Object.keys(bqProjects?.locations[areaKey] || {}).map(
                (location) => (
                  <option
                    key={bqProjects?.locations[areaKey][location].id}
                    value={bqProjects?.locations[areaKey][location].id}
                  >
                    {bqProjects?.locations[areaKey][location].name}
                    {bqProjects?.locations[areaKey][location].note && " - Geen App Engine"}
                  </option>
                )
              )}
            </optgroup>
          ))}
        </select>
        <Button
          label="Sync toevoegen"
          disabled={
            !state.updateDivision?.projectId ||
            !state.updateDivision?.projectLocation
          }
          icon={faPlus}
          onClick={() => {
            setEditMode(true);
          }}
        />
      </div>
      <p className="text-secondary">
        Selecteer een Google BigQuery project en klik op 'Sync toevoegen' om de
        sync te starten. Het BigQuery project mag hetzelfde zijn als eventuele
        andere syncs.
      </p>
      <p>
        Let op: het is niet mogelijk om views te creëren in BigQuery wanneer de
        data op verschillende locaties staan. Wil je de Exact data combineren
        met jouw eigen data, selecteer dan dezelfde locatie.
      </p>
      <h3 className="text-secondary text-xl mt-4">
        Hoe maak ik een BigQuery project?
      </h3>
      <ul className="text-secondary list-disc pl-6">
        <li>
          Je moet toegang hebben tot Google Cloud Platform. Als je beheerder
          bent,{" "}
          <a
            href="https://support.google.com/a/answer/9197205"
            target="_blank"
            rel="noreferrer"
          >
            bekijk je hoe je GCP aanzet voor je organisatie
          </a>
          .
        </li>
        <li>
          Je moet toegang hebben tot BigQuery.{" "}
          <a
            href="hhttps://cloud.google.com/bigquery/"
            target="_blank"
            rel="noreferrer"
          >
            Meer informatie over hoe je BigQuery kunt gebruiken
          </a>
          .
        </li>
        <li>
          Je moet een project hebben waarvoor facturering is ingesteld in
          BigQuery.{" "}
          <a
            href="https://cloud.google.com/bigquery/docs/sandbox"
            target="_blank"
            rel="noreferrer"
          >
            Meer informatie over hoe je facturering instelt voor Google Cloud
          </a>
          .
        </li>
        <li>
          Als je geen project hebt waarvoor facturering is ingesteld, kun je in
          een proefomgeving met BigQuery-gegevens werken.{" "}
          <a
            href="https://cloud.google.com/bigquery/docs/sandbox"
            target="_blank"
            rel="noreferrer"
          >
            Bekijk meer informatie over hoe je BigQuery kunt uitproberen
          </a>
          .
        </li>
        <li>
          <a
            href="https://support.google.com/a/answer/9604541"
            target="_blank"
            rel="noreferrer"
          >
            Bekijk meer informatie over de rechten en rollen die vereist zijn
            voor toegang tot een BigQuery-tabel of -dataset
          </a>
          .
        </li>
      </ul>
    </>
  );
};
