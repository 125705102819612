import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { AUTHAPI } from "../../utils/api";
import { Loader } from "../ui/loader/Loader";
import { useAuth } from "../../utils/useAuth";
import { toast } from 'react-toastify';

export const ExactAuth: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token, login } = useAuth();

  useEffect(() => {
    if (!token) {
      console.log("No token?");
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const getAllParameters = (): Record<string, string> => {
      const params: Record<string, string> = {};
      queryParams.forEach((value, key) => {
        params[key] = value;
      });
      if (params.error) {
        if (params.error === 'unauthorized_client') {
          toast.error("Exact client_id not allowed for this user.");
        }
      }
      return params;
    };

    // Call redirectUri, sending all params from exact
    const handleRedirect = async () => {
      try {
        toast.info('Checking exact authorization...');

        const result = await AUTHAPI.redirectUri(token, getAllParameters());
        if (result) {
          toast.success('All ok!');
        }
      } catch (error) {
        console.error("Redirect error:", error);
        toast.error("Something went wrong. "+error);

      }
      login(token);
      navigate("/");
    };

    handleRedirect();
  }, [location.search, token]);

  return (
    <div className="flex flex-col items-center mt-16">
      <p className="mb-8">Bijna klaar...</p>
      <Loader />
    </div>
  );
};
