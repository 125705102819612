import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";

export const provider = new GoogleAuthProvider();
const firebaseConfig = {
  apiKey: "AIzaSyAjfyuAGIYEZhg4CeAga2i8d3srnul8Qf4",
  authDomain: "toolinq-synclynx.firebaseapp.com",
  projectId: "toolinq-synclynx",
  storageBucket: "toolinq-synclynx.appspot.com",
  messagingSenderId: "166546442177",
  appId: "1:166546442177:web:9b08857efb8fb098fa1140",
  measurementId: "G-0TEJZNG25T"
};
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
