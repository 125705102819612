import { redirect, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Division } from "../../domain/division";
import { NoSync } from "../syncStates/NoSync";
import { EditSync } from "../syncStates/EditSync";
import { SyncActive } from "../syncStates/SyncActive";
import { useStateContext, useStateDispatch } from "../../state/StateContext";
import { ActionType } from "../../state/stateReducer";
import { Loader } from "../ui/loader/Loader";
import { syncEntitiesAPI } from "../../utils/api";
import { useAuth } from "../../utils/useAuth";

export const DivisionDetails: React.FC = () => {
  const { divisionId } = useParams();
  const state = useStateContext();
  const dispatch = useStateDispatch()!;
  const [editMode, setEditMode] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    if (!state.divisions?.length) {
      return;
    }

    const updateDivision = JSON.parse(
      JSON.stringify(
        state.divisions?.find((division) => division.division === divisionId)
      )
    ) as Division;

    dispatch({
      type: ActionType.updateDivision,
      value: updateDivision,
    });
  }, [divisionId, state.divisions]);

  useEffect(() => {
    // changed division, reset view state
    setEditMode(false);

    // get known entities if undefined
    if (token && divisionId) {
      syncEntitiesAPI.getKnown(token, divisionId).then((res) => {
        dispatch({
          type: ActionType.setKnownEntities,
          value: res
        });
      }).catch(err => {
        console.error(err);
        dispatch({
          type: ActionType.setKnownEntities,
          value: []
        });
      });
    }
  }, [divisionId]);

  if (!state.updateDivision) {
    return <div className="flex justify-center mt-16"><Loader /></div>;
  }
  
  const noSyncActive =
    !state.updateDivision.syncEntities ||
    !state.updateDivision.syncEntities.length ||
    !state.updateDivision.syncEntities?.filter(
      (entity) => entity.store !== null
    ).length;

  // all entities have empty store, so no sync is active
  if (!editMode && noSyncActive) {
    return (
      <div className="my-3 p-3 bg-bg rounded-l-xl overflow-y-auto flex flex-col gap-6">
        <NoSync setEditMode={setEditMode} />
      </div>
    );
  }

  return (
    <div className="my-3 p-3 bg-bg rounded-l-xl overflow-y-auto flex flex-col gap-6">
      <div className="flex flex-wrap gap-3">
        <h2 className="text-2xl text-secondary grow">
          {state.updateDivision.name}
        </h2>
      </div>
      {!editMode && <SyncActive setEditMode={setEditMode} />}
      {editMode && <EditSync saveCallback={() => setEditMode(false)} />}
    </div>
  );
};
