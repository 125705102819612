export enum SyncStatus {
  noSync = "noSync",
  ok = "ok",
  error = "error",
  pause = "pause"
}

export interface BaseEntity {
  url: string;
  entityName: string;
  exactMetaEntityName: string;
  syncAttributeKey: string;
  lastChangeKey?: string;
  UserHasRights: boolean;
}

export interface Entity extends BaseEntity {
  tableName: string;
  division: number;
  projectId: string;
  projectLocation: string;
  datasetId: string;
  lastChangeKey: string;
  syncAttributeKey: string;
  selectFields: string; // default all, not settable in ui
  fields: Array<string>;
  key: ""; // todo: ?
  store: null | EntityStore;
}

// todo: check types
export interface EntityStore {
  lastChange: string;
  lastSync: string;
  syncAttributeKeyValue: number;
  __next: string;
  status: SyncStatus;
  resync: boolean;
  info?: string;
}