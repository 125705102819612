import { faLinkSlash, faRightFromBracket, faToggleOn, faToggleOff, faExclamationTriangle, faQuestion } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Button } from './ui/Button';
import { useAuth } from '../utils/useAuth';
import { syncEntitiesAPI } from '../utils/api';
import { Loader } from './ui/loader/Loader';

export const Header: React.FC = () => {
  const { logout, exactLogout, token } = useAuth();
  const [cronStatus, setCronStatus] = useState(''); // state to keep track of cron job status
  const [loading, setLoading] = useState(true); // state to handle loading state
  const [showIframe, setShowIframe] = useState(false); // state to handle iframe visibility
  const [iframeLoading, setIframeLoading] = useState(false); // state to handle iframe loading status
  const url = process.env.REACT_APP_SCRIPT_EXEC_URL!;
  // Function to fetch the cron job status
  const fetchStatus = async (authToken: string) => {
    setLoading(true);
    
    try {
      const result = await syncEntitiesAPI.getTriggerStatus(authToken);
      
      console.log('result', result);
      if (result === 'enabled' || result === 'stopped') {
        setCronStatus(result);      
      } else {
        console.log(result);
        setCronStatus('error');
      }
    } catch (error) {
      console.log('Error fetching status:', error);
      setCronStatus('error');
    } finally {
      setLoading(false);
    }
  };

  // Function to handle enabling/disabling the cron job
  const handleToggleStatus = () => {
    setShowIframe(true);
    setIframeLoading(true); // Set iframe loading status to true
  };

  // Function to handle iframe close
  const handleCloseIframe = () => {
    if (token) {
      setShowIframe(false);
      fetchStatus(token); // Refresh the status when iframe is closed
    }
  };

  // Handle iframe load event
  const handleIframeLoad = () => {
    setIframeLoading(false); // Set iframe loading status to false
  };

  useEffect(() => {
    if (token) {
      fetchStatus(token); // Fetch the status when the component mounts if token exists
    }
  }, [token]);

  return (
    <>
      <header
        className="bg-bg justify-between items-stretch flex w-full gap-5 p-3 rounded-b-xl max-md:max-w-full max-md:flex-wrap max-md:px-5">
        <div className="h-10 flex gap-3">
          <img src="/assets/logo_new.png" alt="SyncLynx Logo" />
          <p className="font-montserratbold text-2xl text-primary self-center">Sync Lynx</p>
        </div>
        <div className="flex gap-8">
          <Button type="ghost" label="Exact ontkoppelen" icon={faLinkSlash} onClick={() => { exactLogout() }} />
          <Button type="ghost" label="Uitloggen" icon={faRightFromBracket} onClick={() => { logout() }} />
          {token && (
            loading ? (
              <Button type='ghost' 
                label='Getting sync status'
                icon={faQuestion}
              />
            ) : cronStatus === 'error' ? (
              <Button type="ghost" label="Error" icon={faExclamationTriangle} onClick={() => console.log('Error fetching status')} />
            ) : (
              <Button
                type="ghost"
                label={cronStatus === 'enabled' ? 'Sync enabled' : 'Sync disabled'}
                icon={cronStatus === 'enabled' ? faToggleOn : faToggleOff}
                onClick={handleToggleStatus}
              />
            )
          )}
        </div>
      </header>
      {showIframe && (
        <div className="iframe-overlay">
          <div className="iframe-container">
            {iframeLoading && (
              <div className="flex flex-col items-center m-8">
                <p className="text-primary text-lg mb-8">Loading synchronisation setup from your Google-account...</p>
                <Loader />
              </div>
            )}
            <iframe title="Toggle Sync" src={url + "?initial="+cronStatus} width="400" height="300" onLoad={handleIframeLoad} />
            <button className="close-button" onClick={handleCloseIframe}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}
