import { Outlet } from "react-router-dom";
import { DivisionList } from "./DivisionList";
import { Header } from "../Header";
import { useEffect } from "react";
import { useAuth } from "../../utils/useAuth";
import { useStateDispatch } from "../../state/StateContext";
import { ActionType } from "../../state/stateReducer";
import { divisionAPI } from "../../utils/api"

export const DivisionContainer: React.FC = () => {
  const { token } = useAuth();
  const dispatch = useStateDispatch()!; // todo: type

  useEffect(() => {
    if (!token) {
      return;
    }

    divisionAPI.getAll(token).then((res) => {
      dispatch({
        type: ActionType.setDivisions,
        value: res
      });
    }).catch(err => {
      console.error(err);
      dispatch({
        type: ActionType.setDivisions,
        value: []
      });
    });
  }, []);

  return (
    <>
      <Header />
      <main className="grid md:grid-cols-2 grow">
        <DivisionList />
        <Outlet />
      </main>
    </>
  );
};
