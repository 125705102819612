import React, { useReducer } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  StateContext,
  DispatchStateContext,
  initialState,
} from "./state/StateContext";
import { DivisionDetails } from "./components/division/DivisionDetails";
import { DivisionContainer } from "./components/division/DivisionContainer";
import { Login } from "./components/login/Login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./utils/useAuth";
import { stateReducer } from "./state/stateReducer";
import { ExactAuth } from "./components/login/ExactAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./toastr-colors.css";

const isSmallScreen = window.matchMedia("(max-width: 768px)").matches; // todo: on window resize

export const App: React.FC = () => {
  const [state, stateDispatch] = useReducer(stateReducer, initialState);

  return (
    <div className="flex flex-col h-dvh">
      <BrowserRouter>
        <GoogleOAuthProvider clientId="166546442177-24mdl36a80vlhuc4rmhv61hn46pp64mv.apps.googleusercontent.com">
          <AuthProvider>
            <StateContext.Provider value={state}>
              <DispatchStateContext.Provider value={stateDispatch}>
                <Routes>
                  <Route path="" element={<Login />} />
                  <Route path="exact/auth" element={<ExactAuth />} />
                  <Route path="divisions" element={<DivisionContainer />}>
                    {!isSmallScreen && (
                      <Route path=":divisionId" element={<DivisionDetails />} />
                    )}
                  </Route>
                  {isSmallScreen && (
                    <Route
                      path="divisions/:divisionId"
                      element={<DivisionDetails />}
                    />
                  )}
                </Routes>
                <ToastContainer position="top-left" />
              </DispatchStateContext.Provider>
            </StateContext.Provider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
