import React from 'react';
import { DivisionCard } from './DivisionCard';
import { useStateContext } from '../../state/StateContext';
import { Loader } from '../ui/loader/Loader';

export const DivisionList: React.FC = () => {
  const state = useStateContext();

  if (!state || !state.divisions) {
    return <div className="flex justify-center mt-16"><Loader /></div>;
  }

  if (!state.divisions.length) {
    return (
      <div className="p-3 flex flex-col gap-3 overflow-y-auto">
        <div className="flex flex-col bg-bg rounded-xl p-3 gap-3 border-2 border-bg">
          <p className="text-bad text-lg">Geen divisies gevonden</p>
          <p className="text-sm text-gray">Heeft u zich aangemeld met de juiste Exact-gebruiker?</p>
        </div>
      </div>
    )
  }

  return (
    <div className="p-3 flex flex-col gap-3 overflow-y-auto">
      {state.divisions.map(division => (
        <DivisionCard key={division.division} division={division} />
      ))}
    </div>
  )
}