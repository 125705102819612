import { createContext, useContext } from 'react';
import { Actions } from './stateReducer';
import { BaseEntity } from '../domain/entity';
import { Division, UpdateDivision } from '../domain/division';

export interface State {
  divisions: Array<Division> | null;
  updateDivision: UpdateDivision | null;
  knownEntities: Array<BaseEntity> | null;
};

export const initialState: State = {
  knownEntities: null,
  divisions: null,
  updateDivision: null
}

export const StateContext = createContext<State>(initialState);
export const DispatchStateContext = createContext<React.Dispatch<Actions> | undefined>(undefined);

export const useStateContext = () => useContext(StateContext);
export const useStateDispatch = () => useContext(DispatchStateContext);
