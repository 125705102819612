import React from "react";
import { Division } from "../../domain/division";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoon,
  faCircleCheck,
  faCircleXmark,
  faCircleQuestion,
} from "@fortawesome/free-regular-svg-icons";
import { NavLink } from "react-router-dom";
import { SyncStatus } from "../../domain/entity";

interface Props {
  division: Division;
}

export const DivisionCard: React.FC<Props> = ({ division }) => {
  const getSyncStatus = () => {
    const entities = division.syncEntities;
    if (!entities || !entities.length) {
      return SyncStatus.noSync;
    }
    if (!!division.error || entities.find((entity) => entity.store?.status === SyncStatus.error)) {
      return SyncStatus.error;
    }
    if (entities.find((entity) => entity.store?.status === SyncStatus.pause)) {
      return SyncStatus.pause;
    }
    if (
      entities.filter((entity) => entity.store?.status === SyncStatus.ok)
        .length === entities.length
    ) {
      return SyncStatus.ok;
    }
    return undefined;
  };

  const getStateIcon = (state?: SyncStatus) => {
    switch (state) {
      case SyncStatus.noSync:
        return (
          <FontAwesomeIcon icon={faMoon} className="text-5xl text-gray w-12" />
        );
      case SyncStatus.ok:
        return (
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="text-5xl text-good w-12"
          />
        );
      case SyncStatus.error:
        return (
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="text-5xl text-bad w-12"
          />
        );
      default:
        return (
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className="text-5xl text-gray w-12"
          />
        );
    }
  };

  const syncStatusToText = (state?: SyncStatus) => {
    switch (state) {
      case SyncStatus.noSync:
        return "Geen sync";
      case SyncStatus.ok:
      case SyncStatus.error:
        return `Laatste sync: ${
          division.syncEntities?.[0].store?.lastSync
            ? new Date(
                division.syncEntities?.[0].store?.lastSync
              ).toLocaleString("NL-nl")
            : "onbekend"
        }`;
      default:
        return "Onbekend";
    }
  };

  return (
    <NavLink
      to={`${division.division}`}
      className={({ isActive }) =>
        isActive
          ? "active-division flex flex-row bg-bg rounded-xl p-3 gap-3 border-2 border-bg cursor-pointer hover:border-white hover:bg-white"
          : "flex flex-row bg-bg rounded-xl p-3 gap-3 border-2 border-bg cursor-pointer hover:border-white hover:bg-white"
      }
    >
      {getStateIcon(getSyncStatus())}
      <div className="flex flex-col grow">
        <h3 className="text-lg text-secondary">{division.name}</h3>
        <p className="text-sm text-gray">
          Status: {syncStatusToText(getSyncStatus())}
        </p>
      </div>
      <div className="flex gap-2 items-center mr-2 text-sm text-gray">
        <p>
          {!!division.syncEntities?.length &&
            `${division.syncEntities.length} geselecteerd`}
        </p>
      </div>
    </NavLink>
  );
};
