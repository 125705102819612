export interface UserDto {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  email: string;
  email_verified: boolean;
  locale: string;
}

export class User {
  protected constructor(
    public readonly fullName: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly email: string,
    public readonly imageUrl: string | null
  ) {}

  public static make(dto: UserDto) {
    return new User(
      dto.name,
      dto.given_name,
      dto.family_name,
      dto.email,
      dto.picture
    );
  }
}
