import { Division } from "../domain/division";
import { BaseEntity } from "../domain/entity";
import { State } from "./StateContext";

export enum ActionType {
  setDivisions,
  clearDivisions,
  setKnownEntities,
  updateDivision
}

export interface Actions {
  type: ActionType;
  value?: Array<Division> | Division | Array<BaseEntity>;
}

export function stateReducer(
  _state: State,
  action: Actions,
): State {
  switch (action.type) {
    case ActionType.setDivisions: {
      if (!action.value || !Object.hasOwn(action.value, 'length')) {
        throw Error(
          `'setDivisions' action must have value, got ${action.value}`,
        );
      }
      return {
        ..._state,
        divisions: action.value as Array<Division>
      };
    }
    case ActionType.clearDivisions: {
      return {
        ..._state,
        divisions: []
      };
    }
    case ActionType.setKnownEntities: {
      return {
        ..._state,
        knownEntities: (action.value || []) as Array<BaseEntity>
      }
    }
    case ActionType.updateDivision: {
      if (!action.value || Object.hasOwn(action.value, 'length')) {
        throw Error(
          `'updateDivision' action must be Division type, got ${action.value}`,
        );
      }

      // todo: fix type casting
      return {
        ..._state,
        updateDivision: action.value as Division
      }
    }
    default: {
      throw Error(`Unknown reducer action: ${action.type}`);
    }
  }
}
