import {
  faCheckDouble,
  faListCheck,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Button } from "../ui/Button";
import {  UpdateDivision } from "../../domain/division";
import { useParams } from "react-router-dom";
import { useStateContext, useStateDispatch } from "../../state/StateContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseEntity } from "../../domain/entity";
import { ActionType } from "../../state/stateReducer";
import { Loader } from "../ui/loader/Loader";
import { divisionAPI, syncEntitiesAPI } from "../../utils/api";
import { useAuth } from "../../utils/useAuth";
import { toast } from "react-toastify";

interface Props {
  saveCallback: () => void;
}

export const EditSync: React.FC<Props> = ({ saveCallback }) => {
  const state = useStateContext();
  const { divisionId } = useParams();
  const { token } = useAuth();
  const dispatch = useStateDispatch()!; // todo: type
  const [loading, setLoading] = useState(false);

  if (!state.updateDivision || !state.knownEntities) {
    return <div className="flex justify-center mt-16"><Loader /></div>;;
  }

  const toggleEntityState = (toggleEntity: BaseEntity) => {
    console.log(toggleEntity)
    if (!toggleEntity.UserHasRights) {
      toast.warn('Geen rechten voor dit endpoint in Exact');
      return;
    }
    const updateEntity = state.updateDivision?.syncEntities?.find(
      (entity) => entity.url === toggleEntity.url
    );

    // state is immutable, copy it so we don't update references in state
    const updateDivision = JSON.parse(
      JSON.stringify(state.updateDivision)
    ) as UpdateDivision;

    // does not exist in the array yet, add
    if (!updateEntity) {
      updateDivision.syncEntities = (updateDivision.syncEntities || []).concat({
        ...toggleEntity,
        action: "create",
        tableName: toggleEntity.entityName.replace(/[^a-zA-Z0-9]+/g, ""),
        division: +divisionId!,
        projectId: updateDivision.projectId,
        projectLocation: updateDivision.projectLocation,
        datasetId: updateDivision.name.replace(/[^a-zA-Z0-9]+/g, ""),
        lastChangeKey: toggleEntity.lastChangeKey || "Modified",
        selectFields: "all",
        fields: [],
        store: null,
        key: "",
      });
    } else {
      updateDivision.syncEntities = updateDivision.syncEntities?.map((ent) =>
        ent.url === toggleEntity.url
          ? { ...ent, action: ent.action === "delete" ? "create" : "delete" }
          : ent
      );
    }

    dispatch({
      type: ActionType.updateDivision,
      value: updateDivision,
    });
  };

  const testSync = async () => {
    if (!state.updateDivision?.syncEntities) {
      return;
    }
    setLoading(true);
    const t = toast("Bezig met testen endpoints",{'position':'top-left',autoClose: false});

    const division = state.updateDivision.division
    

    // todo: refactor
    const testDivisionResult = await divisionAPI.test(token!, division);
    let trigger;
    if (testDivisionResult) {
      if (testDivisionResult.trigger === "enabled") {
        trigger = "OK"
      } else {
        trigger = "FOUT"
      }
    }
    const result = testDivisionResult.entities.find((entity) => {
      return entity.store?.status === "error"
    });
    if (result) {
      toast("Sync: "+trigger+", API: "+result.entityName+" error. \r\n"+ result.store?.info ,{'position':'top-left','type':'error'});

    } else {
      toast("Sync: "+trigger+", API: OK" ,{'position':'top-left','type':'success'});

    }
    toast.dismiss(t);

    const t2 = toast("Updaten..." ,{'position':'top-left','type':'info', autoClose: false});

    setLoading(false);
    const refreshDivisions = await divisionAPI.getAll(token!);
    dispatch({
      type: ActionType.setDivisions,
      value: refreshDivisions,
    });
    toast.dismiss(t2);

    saveCallback();
  }

  const save = async () => {
    if (!state.updateDivision?.syncEntities) {
      return;
    }
    setLoading(true);
    const t = toast("Bezig met instellen endpoints",{'position':'top-left','autoClose': false, 'progress': 100});
    let count = 0;
    let done = 0;
    await Promise.all(
      state.updateDivision.syncEntities.map((entity) => {
        count++;
        done++;
        switch (entity.action) {
          case "create":
            return syncEntitiesAPI.createEntitySync(token!, entity).then(() => {
              done--;
              console.log( 100 * (done/count))
              toast.update(t, {'progress': 100 * (done/count) })
            });
          case "delete":
            return syncEntitiesAPI.deleteEntitySync(token!, entity.key).then(() => {
              done--;
              console.log( 100 * (done/count))
              toast.update(t, {'progress': 100 * (done/count) })
            });
          default:
            done--;
            return;
        }
      })
    );

    // todo: refactor
    
    toast.dismiss(t);

    testSync();

    // saveCallback();
  };

  return (
    <>
      <div className="flex">
        <h3 className="text-xl text-secondary grow">Endpoints</h3>
      
        <Button
          label="Opslaan & controleren"
          disabled={loading || !state.updateDivision.syncEntities?.length}
          onClick={save}
          icon={faListCheck}
        />
      </div>
      {state?.knownEntities?.map((entity) => (
        <div key={entity.url} className={`flex ${!entity.UserHasRights && 'opacity-60'}`}>
          <div className="flex-grow">
            <p className="text-md grow">{entity.url}</p>
            {!entity.UserHasRights && <p className="text-xs text-secondary grow">Geen rechten in Exact</p>}
          </div>
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => toggleEntityState(entity)}
          >
            {state.updateDivision!.syncEntities?.some(
              (active) =>
                active.url === entity.url && active.action !== "delete"
            ) ? (
              <>
                <p className="text-md">Aan</p>
                <FontAwesomeIcon
                  className="text-2xl text-primary"
                  icon={faToggleOn}
                />
              </>
            ) : (
              <>
                <p className="text-md">Uit</p>
                <FontAwesomeIcon
                  className={`text-2xl text-primary ${!entity.UserHasRights && 'cursor-not-allowed text-secondary'}`}
                  icon={faToggleOff}
                />
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
